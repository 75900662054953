/* You can add global styles to this file, and also import other style files */


/* Angular material */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';


/* Maybe put this into own scss file -- nextPageOverrides.scss */
/* Override NextPage defaults */
header, nav#site-specific-nav, main, footer {
    max-width: none !important;
}

:root {
    --color-text-light: #607d8b;
    --border-radius: 10px;
    --spacing-step: 8px;
}

mat-table {
  width: 100%;
  //overflow: auto;
}

th.mat-sort-header-sorted {
  color: black;
}